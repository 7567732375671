import React from "react"
import SEO from '../components/SEO'
import Layout from "../components/Layout"
import styled from "styled-components"
import { device } from '../components/Device'

const Container = styled.div`
    margin: auto;
    width: 1024px;
    padding-top: 80px;

    @media ${device.laptop} {
        width: 768px;
    }

    @media ${device.tablet} {
        width: 425px;
    }

    @media ${device.mobileL} {
        width: 314px;
    }
    h1 {
      font-family: Playfair Display;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 40px;
/* identical to box height */

letter-spacing: -0.01em;

/* Core / 100 */

color: #3E3E3E;

text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);
text-align: center;
    }

    p {
      /* P1 - Regular Text Body */

font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
/* or 150% */


/* Core / 80 */

color: #717171;
      margin-top: 40px;
      text-align: center;
      margin-bottom: 200px;
    }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Não encontrada" />
    <Container>
      <h1>PÁGINA NÃO ENCONTRADA</h1>
      <p>Infelizmente a página que você está procurando não foi encontrada ou está fora do ar</p>
    </Container>
  </Layout>
)

export default NotFoundPage
